import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getDashboard(currUserId, filterBy) {
        let url = `${this.baseUrl}/getDashboard`;
        return axios.post(url, {
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    }
}